import { CheckBox } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import BannerContainer from "../components/common/BannerContainer";
import Footer from "../components/Footer";
import GetAQuote from "../components/home/GetAQuote";
import TopbarDark from "../components/TopbarDark";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function Contact_us() {
  const [emailDetails, setEmailDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});

  let validationSchema = Yup.object({
    name: Yup.string().required("This field is Required"),
    email: Yup.string().required("This field is Required"),
    description: Yup.string().required("This field is Required"),
    mobile: Yup.string()
      .min(10, "Mobile number should have atleast 10 digits")
      .required("This field is Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
      mobile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Todo: hit api call here after validation of form
      values.contact_me = values.contact_me < 1;
      console.log(values);
    },
  });

  return (
    <main>
      <TopbarDark />
      <div className="mb-12">
        <section className="mt-12">
          <BannerContainer
            title="get in touch"
            heading="It will be worth it!"
            caption="For Data Analytics that<br /> just work effortlessly."
          />
        </section>
        {/* End of what we do */}
        {/* Contact Form */}
        <div className="px-6 md:px-24 md:py-16 mt-12 flex flex-col md:flex-row justify-between">
          <div className="flex-1 hidden md:block">
            <div className="text-2xl font-semibold">Think Lokal</div>
            <div className="leading-relaxed mt-4">
              2nd Floor, Opposite JNTU, <br />
              Kukatpally, Hyderabad, <br />
              Telangana - 500 085. <br />
              India.
            </div>
            <div className="mt-12 underline underline-offset-4 text-[#57B847]">
              info@thinklokal.com
            </div>

            <div className="mt-6">+91 98863 24237</div>
          </div>
          <div className="flex-1">
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-6 space-y-6" id="contact-us-form">
                <TextField
                  label="Your Name"
                  name="name"
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="standard"
                  fullWidth
                />
                <TextField
                  label="Company Email"
                  name="email"
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="standard"
                  fullWidth
                />
                <TextField
                  label="Phone (Optional)"
                  name="mobile"
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  variant="standard"
                  fullWidth
                />

                <TextField
                  rows={5}
                  multiline
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  name="description"
                  label="Anything we should know/Specific requirements"
                  fullWidth
                  variant="standard"
                  defaultValue={""}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      name="contact_me"
                      onChange={formik.handleChange}
                    />
                  }
                  label="Do not contact me, send me marketing material or send any updates outside this interaction."
                />
                <div>
                  <button
                    type="submit"
                    className="rounded-full bg-[#57B847] px-20 py-4 text-white font-semibold text-2xl my-12"
                  >
                    Submit
                  </button>

                  <div className="hidden flex-1 p-6 justify-center items-center bg-[#F6F6F6] font-semibold">
                    Demo
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </main>
  );
}
